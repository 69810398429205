<template>
  <van-tabbar v-model="currentTabName">
    <van-tabbar-item v-if="![4, 5].includes(userType)" name="index" :to="{ name: 'index' }" icon="wap-home-o">
      首页
    </van-tabbar-item>
    <van-tabbar-item name="message" :to="{ name: 'message' }" icon="envelop-o">
      消息
    </van-tabbar-item>
    <van-tabbar-item name="service" :to="{ name: 'serviceMenu' }" icon="apps-o">
      供应链服务
    </van-tabbar-item>
    <van-tabbar-item name="personal" :to="{ name: 'personal' }" icon="contact">
      我的
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant'
export default {
  name: 'TabBar',
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  props: {
    active: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      employeeInfo: {},
      currentTabName: this.active,
      userType: this._.get(this.$store.state, 'employeeInfo.userType', '')
    }
  },
  created () {
    // this.getEmployeeInfo().then(result => {
    //   this.employeeInfo = result
    // })
  },
  methods: {
    getEmployeeInfo () {
      return new Promise((resolve, reject) => {
        if (this.$store.state.employeeInfo.orgType) {
          resolve(this.$store.state.employeeInfo)
        } else {
          this.api.base.myInfo().then(result => {
            this.$store.commit('updateEmployeeInfo', result.data.value)
            resolve(result.data.value)
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.van-tabbar {
  border-top: 1px solid #EEE;
  // height: 55px;
  // .van-tabbar-item__icon img {
  //   width: 22px;
  //   height: 22px;
  // }
}

// .active_tab img {
//   width: 22px;
//   height: 22px;
// }
</style>
