var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-tabbar",
    {
      model: {
        value: _vm.currentTabName,
        callback: function($$v) {
          _vm.currentTabName = $$v
        },
        expression: "currentTabName"
      }
    },
    [
      ![4, 5].includes(_vm.userType)
        ? _c(
            "van-tabbar-item",
            {
              attrs: {
                name: "index",
                to: { name: "index" },
                icon: "wap-home-o"
              }
            },
            [_vm._v(" 首页 ")]
          )
        : _vm._e(),
      _c(
        "van-tabbar-item",
        {
          attrs: { name: "message", to: { name: "message" }, icon: "envelop-o" }
        },
        [_vm._v(" 消息 ")]
      ),
      _c(
        "van-tabbar-item",
        {
          attrs: {
            name: "service",
            to: { name: "serviceMenu" },
            icon: "apps-o"
          }
        },
        [_vm._v(" 供应链服务 ")]
      ),
      _c(
        "van-tabbar-item",
        {
          attrs: { name: "personal", to: { name: "personal" }, icon: "contact" }
        },
        [_vm._v(" 我的 ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }